import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';

const Layout = ({ children }) => {
  const location = useLocation();
  const hideNavbarPaths = ['/login'];
  const shouldHideNavbar = hideNavbarPaths.includes(location.pathname);

  return (
    <div className="App container-fluid no-padding">
      {!shouldHideNavbar && <Navbar />}
      {children}
    </div>
  );
};

export default Layout;
