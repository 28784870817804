import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import './initialPromptSearchStyles.css';
import { ToastContainer, toast } from 'react-toastify'; 
import { authHeader, logout } from './Auth';

const InitialPromptSearch = () => {

    const [imageSrc, setImageSrc] = useState(null);
    const location = useLocation();
    const [promptTerm, setPromptTerm] = useState(location.state.promptTerm);
    const [changePromptTerm, setChangePromptTerm] = useState('');
    const [error, setError] = useState('');
    const [changePrompt, setChangePrompt] = useState(true);
    const [changePromptFlag, setChangePromptFlag] = useState(false);
    const [buttonTrigger, setButtonTrigger] = useState(0);
    // const [isLoading, setIsLoading] = useState(true);

    const [feedbackSelect, setFeedbackSelect] = useState([false, false]);
    const [feedback, setFeedback] = useState(null);
    const [feedbackFlag, setFeedbackFlag] = useState(false);

    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])

    useEffect(() => {
        var suffixPrompts = "";
        if(promptTerm.toLowerCase().includes("floor plan"))
            suffixPrompts = "top view, 16:9";
        // else suffixPrompts = ", cinematic lighting, cinematic materials, vray, award, winning photo, 16:9";
        else suffixPrompts = ", exterior view, realistic, 8k"
        // console.log(promptTerm + suffixPrompts);

        fetch('https://tapir-dynamic-personally.ngrok-free.app/generate_lock', {
            mode: "cors",
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=UTF-8",
                ...authHeader()
             },
            body: JSON.stringify({
              "prompt": promptTerm + suffixPrompts,
              "batch_size": 4
            })
          }).then((response) => {
            if (response.status === 401 || response.status === 422) {
                logout();
                console.log("Logged out.");
                return null; 
            }

            if(response.status != 200){
                console.log(response);
                throw Error('could not fetch the data for that resource');
            }
            console.log(response); 
            return response.json();
          }).then((data) => {  

            setImageSrc(data["images"].map((el, idx) =>{
                return `data:image/png;base64,${el}`;
            }));
            
            // console.log(imageSrc);
          }).catch(err => {
            setError(err.message);
          })
    }, [buttonTrigger]);
    
    // Tracking Image Source
    useEffect(()=>{
        console.log(imageSrc);
    }, [imageSrc]);

    const handleChangePrompt = (e) => {
        console.log("Change Prompt Initiated.", e);
        setChangePrompt(false);
        setChangePromptFlag(true);
    }

    const handleChangePromptGeneration = (e) => {
        console.log("Change Prompt Generation Initiated.", e);
        setChangePrompt(true);
        setChangePromptFlag(false);
        setImageSrc(null);
        setPromptTerm(changePromptTerm);
        setButtonTrigger(prev_count => prev_count + 1);
    }

    const handleCancelChangePrompt = (e) => {
        console.log("Cancelled Change Prompt.", e);
        setChangePromptFlag(false);
        setChangePrompt(true);
    }

    const handleFeedbackSelect = (e, feedback_idx) => {
        setFeedbackSelect((prevState) => {
            const ref = [false, false];
            ref[feedback_idx] = true;
            return ref;
        });
        console.log("feedback")
        setFeedback(feedback_idx);
        setFeedbackFlag(true);
    };

    const handleFeedbackSubmit = async(e) => {
        const formData = new FormData();
        formData.append("generatedBase64Image", imageSrc); 
        formData.append("rating", feedback); 
        formData.append("prompt", promptTerm);

        fetch('https://tapir-dynamic-personally.ngrok-free.app/record_rating_from_prompt_generation', {
            method: 'POST',
            body: formData,
            mode: "cors",
            headers: {
                ...authHeader()
            }
            // headers: { 'Content-Type': 'multipart/form-data' } 
        })
        .then((response) => {

            setFeedbackFlag(false);
            setFeedback(null);
            setFeedbackSelect([false, false]);

            if (response.status === 401 || response.status === 422) {
                logout();
                console.log("Logged out.");
                return null; 
            }

            if(response.status != 200){
                console.log(response);
                throw Error('could not submit the feedback');
            }
            return response.json().then((data) => {
                console.log("Feedback response", data);
                return data; 
            }); })
        .then((data) => {
        })
        .catch((err) => {
            console.error('Error', err);
        });
    }

    const handleDownloadTrigger = (e) => {
        const link = document.createElement('a');
        link.href = imageSrc;
        link.download = 'rendered-image.jpg'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success('Your have downloaded the rendered Image ✨');

        const formData = new FormData();
        formData.append('generatedBase64Image', imageSrc);

        fetch('https://tapir-dynamic-personally.ngrok-free.app/record_download_request', {
                method: 'POST',
                body: formData,
                mode: "cors",
                headers: {
                    ...authHeader()
                }
            })
            .then(response => {
                if (response.status === 401 || response.status === 422) {
                    logout();
                    console.log("Logged out.");
                    return null; 
                }
    
                if(response.status != 200){
                    console.log(response);
                    throw Error('could not submit the feedback');
                }

                return response.json().then((data) => {
                    console.log("Download Req response", data);
                    return data; 
                }); }).then(data => {
            })
            .catch(error => {
                console.error('Error:', error);  
            });
    }

    return ( 
        <div className="initial-prompt-search-wrapper">
            {/* <Navbar /> */}
            <div className="initial-prompt-search-body">
                <div className="prompt-caption">
                    Generating Images for <span className="prompt-title">
                        {promptTerm}
                    </span>
                </div>

                {changePrompt && (
                    <div className="change-prompt-wrapper">
                        <button onClick={handleChangePrompt} className = "change-prompt-button">
                            Change Search
                        </button>
                    </div>
                )}

                {changePromptFlag && (
                    <div className="change-prompt-flag-wrapper">
                        <Row className="change-prompt-flag-wrapper-row">
                            <Col lg = {6} md = {6}>
                                <div className="prompt-change-search-wrapper">
                                    <input type="text" 
                                    className="prompt-change-search-input"
                                    placeholder='Enter your Search here'
                                    required
                                    value = {changePromptTerm}
                                    onChange = {(e) => setChangePromptTerm(e.target.value)}/>
                                </div>
                            </Col>
                            <Col lg = {6} md = {6}>
                                <Row>
                                    <Col lg = {6} md = {6} className = "prompt-change-search-col">
                                        <button onClick={handleChangePromptGeneration} className = "prompt-change-search-generate-button">
                                            Generate
                                        </button>
                                    </Col>
                                    <Col lg = {6} md = {6} className = "prompt-change-search-col">
                                        <button onClick={handleCancelChangePrompt} className = "prompt-change-search-cancel-button">
                                            Cancel
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
                
            </div>

            {!imageSrc && (
                <div className="loading-section">
                    <Spinner style={{ width: "4vmax", height: "4vmax" }} animation="border" />
                </div>
            )}

            {error && (
                <div className="error-detected">
                    <h2>{error}</h2>
                </div>
            )}
            <div className="images-generated-wrapper">
                <Row className="image-row">
                    {imageSrc && 
                        imageSrc.map((el, idx) =>{
                            return (
                                <Col lg = {6} md = {6} className="image-col mx-auto" key = {idx}>
                                    <img className="generated-massing-image" src={el} alt="Image" />
                                </Col>
                            )
                        })      
                    }
                </Row>
                
                {imageSrc && (
                    <button className="download-btn" onClick={handleDownloadTrigger}>Download</button>
                )}

                <ToastContainer />
                
                <div className="feedback-wrapper">
                    {!feedbackSelect[1] && (
                        <div className="feedback-btn like-btn" onClick={(e) => handleFeedbackSelect(e, 1)}>
                            👍
                        </div>
                    )}

                    {feedbackSelect[1] && (
                        <div className="feedback-btn-clicked like-btn">
                            👍
                        </div>
                    )}

                    {!feedbackSelect[0] && (
                        <div className="feedback-btn dislike-btn" onClick={(e) => handleFeedbackSelect(e, 0)}>
                            👎
                        </div>
                    )}

                    {feedbackSelect[0] && (
                        <div className="feedback-btn-clicked dislike-btn">
                            👎
                        </div>
                    )}

                    {feedbackFlag && (
                        <div className="submit-feedback">
                            <button className="massing-btn feedback-submit" onClick={handleFeedbackSubmit}>Submit Feedback</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
     );
}
 
export default InitialPromptSearch;