import { Row, Col } from "react-bootstrap";
// import image1 from './assets/1.jpg';
// import image2 from './assets/2.jpg';
// import image3 from './assets/5.jpg';
import "./navbarStyles.css";
import "./homeStyles.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

// import gen1 from "./assets/images/generated/1.jpg";

const HomeScreen = () => {
  const [promptTerm, setPromptTerm] = useState("");
  const navigate = useNavigate();
  const [samplePromptList, setSamplePromptList] = useState([
    "Interior Photograph of a bright Modern House",
    "Exterior view of a Contemporary House in an Oasis with Palm Trees",
    "Exterior view of a Colonial Style Home with spacious Windows",
    "Exterior view of a Majestic Modern Mansion",
    "Modern Kitchen with bright lighting in the Ceiling",
    "Modern Luxury House with an Infinite-Edge Pool",
    "Modern Contemporary Interior Home Design",
    "Futuristic Minimalist living room, Zen Garden",
  ]);

  const handleClick = (e) => {
    e.preventDefault();
    console.log("Clicked. ", e);
    navigate("/search", {
      state: {
        promptTerm: promptTerm,
      },
    });
  };

  const samplePromptChange = (idx) => {
    setPromptTerm(samplePromptList[idx]);
    console.log("Sample Search Term: " + samplePromptList[idx] + " selected.");
  };

  const images = require.context("./assets/images/generated", true);
  const imageList = images.keys().map((image) => images(image));

  return (
    <div className="home-screen-wrapper">
      {/* <Navbar /> */}
      <div className="home-screen-body">
        {/* <div className="hero-title">
                    Generate Your Dream <br/>
                </div> */}
        <div className="container-fluid no-padding">
          <form onSubmit={handleClick}>
            <Row className="align-items-center search-section-wrapper">
              <div className="container-fluid no-padding">
                <div className="container">
                  <h1 className="title-main">Generate a Reference Image</h1>
                  <Col lg={12} md={12} sm={12} className="search-section-col">
                    <div className="prompt-search-wrapper">
                      <input
                        type="text"
                        className="prompt-search-input"
                        placeholder="Enter your prompt here..."
                        required
                        value={promptTerm}
                        onChange={(e) => setPromptTerm(e.target.value)}
                      />
                      <button className="prompt-search-button">Generate</button>
                    </div>
                  </Col>
                  <Row className="hero-sub-title align-items-center">
                    {/* <Col lg = {4} md = {4} sm = {12}>
                            <div className="sub-title">
                                Home.
                            </div>
                        </Col> */}
                    <Col lg={12} md={12} sm={12} className="mx-auto">
                      <div className="sample-prompt-wrapper">
                        <div className="sample-prompt-title">
                          Try these Sample Searches
                        </div>
                        {samplePromptList.map((el, idx) => {
                          return (
                            <div
                              className="sample-prompt-tip"
                              onClick={(e) => samplePromptChange(idx)}
                            >
                              {el}
                            </div>
                          );
                        })}
                        {/* <Row>
                                    <Col>
                                        <div className="sample-prompt" onClick={(e) => samplePromptChange(0)}>
                                            Interior Photograph of a bright Modern House
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="sample-prompt" onClick={(e) => samplePromptChange(1)}>
                                           Exterior view of a Contemporary House in an Oasis with Palm Trees
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="sample-prompt" onClick={(e) => samplePromptChange(2)}>
                                            Exterior view of a Colonial Style Home with spacious Windows
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="sample-prompt" onClick={(e) => samplePromptChange(3)}>
                                            Exterior view of Majestic Modern Mansion
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="sample-prompt" onClick={(e) => samplePromptChange(4)}>
                                            Modern Kitchen with bright lighting in the Ceiling
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="sample-prompt" onClick={(e) => samplePromptChange(5)}>
                                            Modern Luxury House with an Infinite-Edge Pool
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="sample-prompt" onClick={(e) => samplePromptChange(6)}>
                                            Modern Contemporary Interior Home Design
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="sample-prompt" onClick={(e) => samplePromptChange(7)}>
                                            Futuristic Minimalist living room, Zen Garden
                                        </div>
                                    </Col>
                                </Row> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* <Col lg={4} md={4} sm={12} className="search-section-col">
              <div className="buttom-wrapper">
                <button className="prompt-search-button">Generate</button>
              </div>
            </Col> */}
            </Row>
          </form>
        </div>

        <div className="container-fluid py-xl">
          <div className="container">
            <div className="section-title">
            <h4>Generated with descon.ai</h4>
            <span>See what people are making using descon.ai</span>
            </div>
            <div className="portfolio-wrap">
              {imageList.map((image, index) => (
                <div lg={4} md={4} sm={12} className="img-tile">
                  <img key={index} src={image} alt={`image-${index}`} />
                </div>
              ))}
              {/* <div lg={4} md={4} sm={12} className="img-tile">
                <img src={gen1} />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
