import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './authConfig';

const clientId = '654742665578-1so6hske4503hjeopb5g283sdhtg25un.apps.googleusercontent.com';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </GoogleOAuthProvider>
);

