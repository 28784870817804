import { Row, Col } from 'react-bootstrap';
import './navbarStyles.css';
import logo from './assets/logo-main.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

const Navbar = () => {
    const [active, setActive] = useState([true, false]);
    const navigate = useNavigate();

    const handleModelNavClick = (e, model_id) => {
        // console.log("Clicked", e, model);
        setActive((prevState) => {
            const ref = [false, false];
            ref[model_id] = true;
            console.log("ref", ref, "model_id", model_id);
            return ref;
        })
        
        if(model_id == 0) {
            navigate("/");
        }
        else {
            navigate("/massing");
        }
    }
    
    const handleHomeLogoNav = (e) => {
        setActive([true, false]);
        navigate("/");
    }

    return ( 
        <div className="navbar-wrapper">
                <Row className='navbar-logo-row'>
                    <Col md={2} className="navbar-logo-col">
                        <img src={logo} className="logo" alt="logo" onClick={handleHomeLogoNav} />
                    </Col>
                    <Col md={10} className="navbar-selector-col">
                        <div className="model-select-wrapper">
                            {!active[0] && (
                            <div className="topbar-button model-nugget" onClick={(e) => handleModelNavClick(e, 0)}>
                                Generate a Reference Image
                            </div>)}
                            {active[0] && (
                            <div className="topbar-button model-nugget-clicked">
                                Generate a Reference Image
                            </div>)}
                            {!active[1] && (
                            <div className="topbar-button model-nugget" onClick={(e) => handleModelNavClick(e, 1)}>
                                Visualize your Massing
                            </div>)}
                            {active[1] && (
                            <div className="model-nugget-clicked">
                                Visualize your Massing
                            </div>)}
                        </div>
                    </Col>
                </Row>
        </div>
     );
}
 
export default Navbar;