import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { useNavigate } from 'react-router-dom';

const MicrosoftLoginButton = () => {
  const { instance } = useMsal();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLoginSuccess = async (response) => {
    console.log('Microsoft Login Success:', response);
    const accessToken = response.accessToken;
    console.log('Access Token:', accessToken);

    fetch('https://merry-tortoise-moved.ngrok-free.app/verify-microsoft-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: accessToken })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        localStorage.setItem('user', JSON.stringify(data.user));
        navigate('/');
      } else {
        setError('Login failed: ' + data.message);
      }
    })
    .catch(error => {
      console.error('Error:', error);
      setError('An error occurred during login.');
    });
  };

  const handleLoginError = (errorResponse) => {
    console.error('Microsoft Login Failed:', errorResponse);
    setError('Microsoft Login Failed. Please try again.');
  };

  const handleLogin = () => {
    instance.loginPopup(loginRequest)
      .then(handleLoginSuccess)
      .catch(handleLoginError);
  };

  return (
    <div>
      <button onClick={(e) => {
        e.preventDefault();
        console.log("Microsoft Login button clicked");
        handleLogin()
        }} className="oauth-login-btn">
        Login with Microsoft
      </button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

export default MicrosoftLoginButton;
