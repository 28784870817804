import { Row, Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { authHeader, logout } from "./Auth";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TextMassing = () => {
  const [promptTerm, setPromptTerm] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);
  const [massingSrc, setMassingSrc] = useState(null);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [style, setStyle] = useState(0);
  const [styleSelect, setStyleSelect] = useState([true, false, false, false]);

  const [upscaleFlag, setUpscaleFlag] = useState(true);
  const [upscaleLoading, setUpscaleLoading] = useState(false);

  const [enhanceFlag, setEnhanceFlag] = useState(true);
  const [enhanceLoading, setEnhanceLoading] = useState(false);

  const [feedbackSelect, setFeedbackSelect] = useState([false, false]);
  const [feedback, setFeedback] = useState(null);
  const [feedbackFlag, setFeedbackFlag] = useState(false);
  const [feedbackHide, setFeedbackhide] = useState(true);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);

    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  const handleFileChange = (e) => {
    if (!feedbackHide) {
      toast.error("Please fill the Feedback");
      return;
    }

    const file = e.target.files[0];
    if (!file) {
      return;
    }
    console.log("File Changed", e);
    setMassingSrc(URL.createObjectURL(file));
    setSelectedFile(e.target.files[0]);
    const label = document.querySelector(".custom-file-upload-text-massing");
    label.textContent = `Massing File: ${file.name}`;
  };

  const handleFileUpload = (e) => {
    if (!selectedFile) {
      toast.error("Please select a massing file");
      return;
    }

    if (promptTerm == "") {
      toast.error("Please enter a style for visualizing the Massing");
      return;
    }

    if (!feedbackHide) {
      toast.error("Please fill the Feedback");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    const jsonData = JSON.stringify({
      prompt: promptTerm,
      batch_size: 5,
      style: style,
    });
    formData.append("data", jsonData);

    console.log(authHeader());

    setIsLoading(true);

    fetch("https://merry-tortoise-moved.ngrok-free.app/generate_massing_lock", {
      method: "POST",
      body: formData,
      mode: "cors",
      headers: {
        ...authHeader(),
      },
    })
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          logout();
          console.log("Logged out.");
          return null;
        }

        if (response.status != 200) {
          console.log(response);
          throw Error("could not fetch the data for that resource");
        }
        console.log(response);
        setFeedbackhide(false);
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setImageSrc(`data:image/png;base64,${data["images"][0]}`);
        setUpscaleFlag(true);
        setEnhanceFlag(true);
        setError("");
      })
      .catch((err) => {
        console.error("Error", error);
        setIsLoading(false);
        setError(err.message);
      });
  };

  const handleStyleSelect = (e, style_idx) => {
    setStyleSelect((prevState) => {
      const ref = [false, false];
      ref[style_idx] = true;
      return ref;
    });
    setStyle(style_idx);
  };

  const handleFeedbackSelect = (e, feedback_idx) => {
    setFeedbackSelect((prevState) => {
      const ref = [false, false];
      ref[feedback_idx] = true;
      return ref;
    });
    console.log("feedback");
    setFeedback(feedback_idx);
    setFeedbackFlag(true);
  };

  const handleFeedbackSubmit = async (e) => {
    setFeedbackhide(true);
    const massingResponse = await fetch(massingSrc);
    const massingBlob = await massingResponse.blob();

    const formData = new FormData();
    formData.append("generatedBase64Image", imageSrc);
    formData.append("massingBlobImage", massingBlob, "massingImage.png");
    formData.append("rating", feedback);
    formData.append("prompt", promptTerm);

    fetch(
      "https://merry-tortoise-moved.ngrok-free.app/record_rating_from_massing",
      {
        method: "POST",
        body: formData,
        mode: "cors",
        headers: {
          ...authHeader(),
        },
        // headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
      .then((response) => {
        setFeedbackFlag(false);
        setFeedback(null);
        setFeedbackSelect([false, false]);

        if (response.status === 401 || response.status === 422) {
          logout();
          console.log("Logged out.");
          return null;
        }

        if (response.status != 200) {
          console.log(response);
          throw Error("could not submit the feedback");
        }
        return response.json().then((data) => {
          console.log("Feedback response", data);
          return data;
        });
      })
      .then((data) => {})
      .catch((err) => {
        console.error("Error", err);
      });
  };

  const handleUpscaling = (e) => {
    const formData = new FormData();
    formData.append("base_image", imageSrc);

    setUpscaleLoading(true);

    fetch("https://tapir-dynamic-personally.ngrok-free.app/upscale", {
      method: "POST",
      body: formData,
      mode: "cors",
      headers: {
        ...authHeader(),
      },
    })
      .then((response) => {
        setUpscaleFlag(false);

        if (response.status === 401 || response.status === 422) {
          logout();
          console.log("Logged out.");
          return null;
        }

        if (response.status != 200) {
          console.log(response);
          throw Error("could not fetch the data for that resource");
        }
        console.log(response);
        return response.json();
      })
      .then((data) => {
        setUpscaleLoading(false);
        setImageSrc(`data:image/png;base64,${data["images"][0]}`);
        toast.success("Your Generated Image has been upscaled ✅");
        setError("");
      })
      .catch((err) => {
        console.error("Error", error);
        setUpscaleLoading(false);
        setError(err.message);
      });
  };

  const handleEnhancing = (e) => {
    const formData = new FormData();
    formData.append("base_image", imageSrc);
    const jsonData = JSON.stringify({
      prompt: promptTerm,
      batch_size: 5,
      style: style,
    });
    formData.append("data", jsonData);

    setEnhanceLoading(true);

    fetch("https://pleasing-corgi-actually.ngrok-free.app/enhance", {
      method: "POST",
      body: formData,
      mode: "cors",
      headers: {
        ...authHeader(),
      },
    })
      .then((response) => {
        setEnhanceFlag(false);

        if (response.status === 401 || response.status === 422) {
          logout();
          console.log("Logged out.");
          return null;
        }

        if (response.status != 200) {
          console.log(response);
          throw Error("could not fetch the data for that resource");
        }
        console.log(response);
        return response.json();
      })
      .then((data) => {
        setEnhanceLoading(false);
        setImageSrc(`data:image/png;base64,${data["images"][0]}`);
        toast.success("Your Generated Image has been Enhanced ✨");
        setError("");
      })
      .catch((err) => {
        console.error("Error", error);
        setEnhanceLoading(false);
        setError(err.message);
      });
  };

  const handleDownloadTrigger = (e) => {
    const link = document.createElement("a");
    link.href = imageSrc;
    link.download = "rendered-image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("Your have downloaded the rendered Image ✨");

    const formData = new FormData();
    formData.append("generatedBase64Image", imageSrc);

    fetch(
      "https://merry-tortoise-moved.ngrok-free.app/record_download_request",
      {
        method: "POST",
        body: formData,
        mode: "cors",
        headers: {
          ...authHeader(),
        },
      }
    )
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          logout();
          console.log("Logged out.");
          return null;
        }

        if (response.status != 200) {
          console.log(response);
          throw Error("could not submit the feedback");
        }

        return response.json().then((data) => {
          console.log("Download Req response", data);
          return data;
        });
      })
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div className="change-prompt-flag-wrapper">
        
        <div className="style-select-wrapper">
          {!styleSelect[0] && (
            <div
              className="style-select-nugget"
              onClick={(e) => {
                handleStyleSelect(e, 0);
              }}
            >
              Regular
            </div>
          )}
          {styleSelect[0] && (
            <div className="style-select-nugget-clicked">Regular</div>
          )}
          {!styleSelect[1] && (
            <div
              className="style-select-nugget-dy"
              onClick={(e) => {
                handleStyleSelect(e, 1);
              }}
            >
              Stylize ✨
            </div>
          )}
          {styleSelect[1] && (
            <div className="style-select-nugget-dy-clicked">Stylize ✨</div>
          )}
          {!styleSelect[2] && (
            <div
              className="style-select-nugget"
              onClick={(e) => {
                handleStyleSelect(e, 2);
              }}
            >
              Scenic
            </div>
          )}
          {styleSelect[3] && (
            <div className="style-select-nugget-clicked">Diverse</div>
          )}
          {!styleSelect[3] && (
            <div
              className="style-select-nugget"
              onClick={(e) => {
                handleStyleSelect(e, 3);
              }}
            >
              Diverse
            </div>
          )}
          {styleSelect[2] && (
            <div className="style-select-nugget-clicked">Scenic</div>
          )}
        </div>

        <div className="image-massing-row mt-3">
          <div className="image-massing-col upload-massing-btn">
            <label
              htmlFor="file-upload-text-massing"
              className="custom-file-upload-text-massing"
            >
              Upload Massing
            </label>
            <input
              id="file-upload-text-massing"
              onChange={handleFileChange}
              type="file"
              style={{ display: "none" }}
            />
          </div>
          <input
            type="text"
            className="massing-search-input"
            placeholder="Enter your Style here"
            required
            value={promptTerm}
            onChange={(e) => setPromptTerm(e.target.value)}
          />
          <div className="massing-btn-wrap">
            {/* <div>
                <ul>
                    <li>Regular</li>
                    <li>Stylize</li>
                    <li>Scenic</li>
                </ul>
                <select>
                    <option>Regular</option>
                    <option>Stylize</option>
                    <option>Scenic</option>
                </select>
            </div> */}
            <button className="massing-btn" onClick={handleFileUpload}>
                Generate
            </button>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="loading-section">
          <Spinner
            style={{ width: "4vmax", height: "4vmax" }}
            animation="border"
          />
        </div>
      )}

      {error && (
        <div className="error-detected">
          <h2>{error}</h2>
        </div>
      )}
      {imageSrc && (
        <div className="images-generated-wrapper">
          {upscaleFlag && (
            <button
              className="custom-file-upload-massing show-reference-btn"
              onClick={(e) => {
                handleUpscaling(e);
              }}
              disabled={upscaleLoading}
            >
              {upscaleLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "➕ Upscale"
              )}
            </button>
          )}

          {enhanceFlag && (
            <button
              className="custom-file-upload-massing show-reference-btn"
              onClick={(e) => {
                handleEnhancing(e);
              }}
              disabled={enhanceLoading}
            >
              {enhanceLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "✨ Enhance"
              )}
            </button>
          )}

          <ToastContainer />

          <Row className="image-row">
            <Col lg={6} md={6} className="image-col">
              <img
                className="generated-massing-image"
                src={massingSrc}
                alt="Massing Image"
              />
            </Col>
            <Col lg={6} md={6} className="image-col">
              <img
                className="generated-massing-image"
                src={imageSrc}
                alt="Generated Image"
              />
            </Col>
          </Row>

          <button className="download-btn" onClick={handleDownloadTrigger}>
            Download
          </button>

          {!feedbackHide && (
            <div className="feedback-wrapper">
              {!feedbackSelect[1] && (
                <div
                  className="feedback-btn like-btn"
                  onClick={(e) => handleFeedbackSelect(e, 1)}
                >
                  👍
                </div>
              )}

              {feedbackSelect[1] && (
                <div className="feedback-btn-clicked like-btn">👍</div>
              )}

              {!feedbackSelect[0] && (
                <div
                  className="feedback-btn dislike-btn"
                  onClick={(e) => handleFeedbackSelect(e, 0)}
                >
                  👎
                </div>
              )}

              {feedbackSelect[0] && (
                <div className="feedback-btn-clicked dislike-btn">👎</div>
              )}

              {feedbackFlag && (
                <div className="submit-feedback">
                  <button
                    className="massing-btn feedback-submit"
                    onClick={handleFeedbackSubmit}
                  >
                    Submit Feedback
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TextMassing;
