import "./imageMassingStyles.css";
import "./multiImageMassing.css";
import { Row, Col, Button, Image } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect, useRef } from "react";
import { authHeader, logout } from "./Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MultiImageMassing = () => {
  const [promptTerm, setPromptTerm] = useState("home, realistic, high quality");
  const [massingFile, setMassingFile] = useState(null);
  const [massingSrc, setMassingSrc] = useState(null);
  const [referenceFile, setReferenceFile] = useState(null);
  const [referenceSrc, setReferenceSrc] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [style, setStyle] = useState(0);
  const [styleSelect, setStyleSelect] = useState([true, false, false, false]);
  const [referenceSelect, setReferenceSelect] = useState(false);

  const [modelSelect, setModelSelect] = useState(0);
  const [modelFlag, setModelFlag] = useState([true, false]);

  const [upscaleFlag, setUpscaleFlag] = useState(true);
  const [upscaleLoading, setUpscaleLoading] = useState(false);

  const [enhanceFlag, setEnhanceFlag] = useState(true);
  const [enhanceLoading, setEnhanceLoading] = useState(false);
  const [enhanceTrigger, setEnhanceTrigger] = useState(0);
  const [enhancedImage, setEnhancedImage] = useState(null);

  const [feedbackSelect, setFeedbackSelect] = useState([false, false]);
  const [feedback, setFeedback] = useState(null);
  const [feedbackFlag, setFeedbackFlag] = useState(false);
  const [feedbackHide, setFeedbackhide] = useState(true);

  const [referenceImages, setReferenceImages] = useState([]);
  const [referenceSliders, setReferenceSliders] = useState([]);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);

    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    if (enhanceTrigger == 0) return;

    const formData = new FormData();
    formData.append("base_image", imageSrc);
    const jsonData = JSON.stringify({
      prompt: promptTerm,
      batch_size: 5,
      style: style,
    });
    formData.append("data", jsonData);

    setEnhanceLoading(true);
    setIsLoading(true);

    fetch("https://pleasing-corgi-actually.ngrok-free.app/enhance", {
      method: "POST",
      body: formData,
      mode: "cors",
      headers: {
        ...authHeader(),
      },
    })
      .then((response) => {
        setEnhanceFlag(false);

        if (response.status === 401 || response.status === 422) {
          logout();
          console.log("Logged out.");
          return null;
        }

        if (response.status != 200) {
          console.log(response);
          throw Error("could not fetch the data for that resource");
        }
        console.log(response);
        return response.json();
      })
      .then((data) => {
        setEnhanceLoading(false);
        setIsLoading(false);
        setImageSrc(`data:image/png;base64,${data["images"][0]}`);
        setEnhancedImage(`data:image/png;base64,${data["images"][0]}`);
        toast.success("Your Generated Image has been Enhanced ✨");
        setError("");
      })
      .catch((err) => {
        console.error("Error", error);
        setEnhanceLoading(false);
        setIsLoading(false);
        setError(err.message);
      });
  }, [enhanceTrigger]);

  const handleFileChangeForMassing = (e) => {
    if (!feedbackHide) {
      toast.error("Please fill the Feedback");
      return;
    }

    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setMassingSrc(URL.createObjectURL(file));
    setMassingFile(file);
    const label = document.querySelector(".custom-file-upload-massing");
    // label.textContent = `Massing File: ${file.name}`;
  };

  const handleFileChangeForReference = (e) => {
    if (!feedbackHide) {
      toast.error("Please fill the Feedback");
      return;
    }

    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setReferenceSrc(URL.createObjectURL(file));
    setReferenceFile(file);
    const label = document.querySelector(".custom-file-upload-reference");
    label.textContent = `Reference File: ${file.name}`;
  };

  const handleFileUploadForGeneration = (e) => {
    if (!massingFile) {
      toast.error("Please select a Massing File");
      return;
    }

    if (referenceImages != null && referenceImages.length == 0) {
      toast.error("Please select a Reference File");
      return;
    }

    if (!feedbackHide) {
      toast.error("Please fill the Feedback");
      return;
    }

    console.log(referenceSliders);

    const formData = new FormData();
    referenceImages.forEach((ele, idx) => {
      formData.append(`referenceFile${idx}`, ele.imageReferenceFile);
      formData.append(`referenceSlider${idx}`, referenceSliders[idx]);
    });
    formData.append("massingFile", massingFile);
    const jsonData = JSON.stringify({
      prompt: promptTerm,
      batch_size: 5,
      style: style,
      model: modelSelect,
    });
    formData.append("data", jsonData);

    setIsLoading(true);

    fetch(
      "https://national-sensibly-urchin.ngrok-free.app/generate_massing_from_references_lock",
      {
        method: "POST",
        body: formData,
        mode: "cors",
        headers: {
          ...authHeader(),
        },
        // headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          logout();
          console.log("Logged out.");
          return null;
        }

        if (response.status != 200) {
          console.log(response);
          throw Error("could not fetch the data for that resource");
        }
        console.log(response);
        setFeedbackhide(false);
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setImageSrc(`data:image/png;base64,${data["images"][0]}`);
        setEnhanceTrigger(enhanceTrigger + 1);
        setUpscaleFlag(true);
        setEnhanceFlag(true);
        setError("");
      })
      .catch((err) => {
        console.error("Error", error);
        setIsLoading(false);
        setError(err.message);
      });
  };

  const handleFeedbackSubmit = async (e) => {
    setFeedbackhide(true);

    const formData = new FormData();
    referenceImages.forEach((ele, idx) => {
      formData.append(`referenceFile${idx}`, ele.imageReferenceFile);
      formData.append(`referenceSlider${idx}`, referenceSliders[idx]);
    });
    formData.append("massingFile", massingFile);
    formData.append("generatedBase64Image", imageSrc);
    formData.append("rating", feedback);

    fetch(
      "https://national-sensibly-urchin.ngrok-free.app/record_rating_from_references",
      {
        method: "POST",
        body: formData,
        mode: "cors",
        headers: {
          ...authHeader(),
        },
        // headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
      .then((response) => {
        setFeedbackFlag(false);
        setFeedback(null);
        setFeedbackSelect([false, false]);

        if (response.status === 401 || response.status === 422) {
          logout();
          console.log("Logged out.");
          return null;
        }

        if (response.status != 200) {
          console.log(response);
          throw Error("could not submit the feedback");
        }
        return response.json().then((data) => {
          console.log("Feedback response", data);
          return data;
        });
      })
      .then((data) => {})
      .catch((err) => {
        console.error("Error", err);
      });
  };

  const handleUpscaling = (e) => {
    const formData = new FormData();
    formData.append("base_image", imageSrc);

    setUpscaleLoading(true);

    fetch("https://tapir-dynamic-personally.ngrok-free.app/upscale", {
      method: "POST",
      body: formData,
      mode: "cors",
      headers: {
        ...authHeader(),
      },
    })
      .then((response) => {
        setUpscaleFlag(false);

        if (response.status === 401 || response.status === 422) {
          logout();
          console.log("Logged out.");
          return null;
        }

        if (response.status != 200) {
          console.log(response);
          throw Error("could not fetch the data for that resource");
        }
        console.log(response);
        return response.json();
      })
      .then((data) => {
        setUpscaleLoading(false);
        setImageSrc(`data:image/png;base64,${data["images"][0]}`);
        setEnhancedImage(`data:image/png;base64,${data["images"][0]}`);
        toast.success("Your Generated Image has been upscaled ✅");
        setError("");
      })
      .catch((err) => {
        console.error("Error", error);
        setUpscaleLoading(false);
        setError(err.message);
      });
  };

  const handleEnhancing = (e) => {
    const formData = new FormData();
    formData.append("base_image", imageSrc);
    const jsonData = JSON.stringify({
      prompt: promptTerm,
      batch_size: 5,
      style: style,
    });
    formData.append("data", jsonData);

    setEnhanceLoading(true);

    fetch("https://pleasing-corgi-actually.ngrok-free.app/enhance", {
      method: "POST",
      body: formData,
      mode: "cors",
      headers: {
        ...authHeader(),
      },
    })
      .then((response) => {
        setEnhanceFlag(false);

        if (response.status === 401 || response.status === 422) {
          logout();
          console.log("Logged out.");
          return null;
        }

        if (response.status != 200) {
          console.log(response);
          throw Error("could not fetch the data for that resource");
        }
        console.log(response);
        return response.json();
      })
      .then((data) => {
        setEnhanceLoading(false);
        setImageSrc(`data:image/png;base64,${data["images"][0]}`);
        toast.success("Your Generated Image has been Enhanced ✨");
        setError("");
      })
      .catch((err) => {
        console.error("Error", error);
        setEnhanceLoading(false);
        setError(err.message);
      });
  };

  const handleStyleSelect = (e, style_idx) => {
    setStyleSelect((prevState) => {
      const ref = [false, false];
      ref[style_idx] = true;
      return ref;
    });
    setStyle(style_idx);
  };

  const handleModelSelect = (e, model_idx) => {
    setModelFlag((prevState) => {
      const ref = [false, false];
      ref[model_idx] = true;
      return ref;
    });
    setModelSelect(model_idx);
  };

  const handleFeedbackSelect = (e, feedback_idx) => {
    setFeedbackSelect((prevState) => {
      const ref = [false, false];
      ref[feedback_idx] = true;
      return ref;
    });
    console.log("feedback");
    setFeedback(feedback_idx);
    setFeedbackFlag(true);
  };

  const handleDownloadTrigger = (e) => {
    const link = document.createElement("a");
    link.href = enhancedImage;
    link.download = "rendered-image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("Your have downloaded the rendered Image ✨");

    const formData = new FormData();
    formData.append("generatedBase64Image", imageSrc);

    fetch(
      "https://national-sensibly-urchin.ngrok-free.app/record_download_request",
      {
        method: "POST",
        body: formData,
        mode: "cors",
        headers: {
          ...authHeader(),
        },
      }
    )
      .then((response) => {
        if (response.status === 401 || response.status === 422) {
          logout();
          console.log("Logged out.");
          return null;
        }

        if (response.status != 200) {
          console.log(response);
          throw Error("could not submit the feedback");
        }

        return response.json().then((data) => {
          console.log("Download Req response", data);
          return data;
        });
      })
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleReferenceImageChange = (e) => {
    if (referenceImages.length < 3) {
      const imageReferenceFile = e.target.files[0];
      if (imageReferenceFile != null) {
        const imageReferenceSrc = URL.createObjectURL(imageReferenceFile);
        setReferenceImages([
          ...referenceImages,
          { src: imageReferenceSrc, imageReferenceFile },
        ]);
        setReferenceSliders([...referenceSliders, 50]);
        console.log(referenceImages);
      }
    } else {
      toast.error("You can only upload up to 3 images");
    }
  };

  const handleSliderChange = (index, value) => {
    const newSliders = [...referenceSliders];
    newSliders[index] = value;
    setReferenceSliders(newSliders);
  };

  const handleDeleteImage = (index) => {
    const newImages = referenceImages.filter(
      (_, imgIndex) => imgIndex !== index
    );
    const newSliders = referenceSliders.filter(
      (_, sliderIndex) => sliderIndex !== index
    );
    setReferenceImages(newImages);
    setReferenceSliders(newSliders);
  };

  return (
    <>
      <ToastContainer />
      <div className="change-prompt-flag-wrapper">
        <div className="section-title text-center mt-5">
          <h4>Step 1: Choose Style</h4>
        </div>

        <div className="style-select-wrapper">
          {!styleSelect[0] && (
            <div
              className="style-select-nugget"
              onClick={(e) => {
                handleStyleSelect(e, 0);
              }}
            >
              Regular
            </div>
          )}
          {styleSelect[0] && (
            <div className="style-select-nugget-clicked">Regular</div>
          )}
          {!styleSelect[1] && (
            <div
              className="style-select-nugget-dy"
              onClick={(e) => {
                handleStyleSelect(e, 1);
              }}
            >
              Stylize ✨
            </div>
          )}
          {styleSelect[1] && (
            <div className="style-select-nugget-dy-clicked">Stylize ✨</div>
          )}
          {!styleSelect[2] && (
            <div
              className="style-select-nugget"
              onClick={(e) => {
                handleStyleSelect(e, 2);
              }}
            >
              Scenic
            </div>
          )}
          {styleSelect[2] && (
            <div className="style-select-nugget-clicked">Scenic</div>
          )}
          {!styleSelect[3] && (
            <div
              className="style-select-nugget"
              onClick={(e) => {
                handleStyleSelect(e, 3);
              }}
            >
              Diverse
            </div>
          )}
          {styleSelect[3] && (
            <div className="style-select-nugget-clicked">Diverse</div>
          )}
        </div>

        <div className="section-title text-center mt-5">
          <h4>Step 2: Choose Model</h4>
        </div>

        <div className="model-select-wrapper">
          {!modelFlag[0] && (
            <div
              className="style-select-nugget"
              onClick={(e) => {
                handleModelSelect(e, 0);
              }}
            >
              Approximate
            </div>
          )}
          {modelFlag[0] && (
            <div className="style-select-nugget-clicked">Approximate</div>
          )}
          {!modelFlag[1] && (
            <div
              className="style-select-nugget"
              onClick={(e) => {
                handleModelSelect(e, 1);
              }}
            >
              Precise
            </div>
          )}
          {modelFlag[1] && (
            <div className="style-select-nugget-clicked">Precise</div>
          )}
        </div>

        {/* <div className="upload-references">
          <div className="upload-reference-hero">References</div>
          <label
            htmlFor="reference-file-upload-massing"
            className="custom-reference-files-upload"
          >
            Upload Reference Images
          </label>
          <input
            id="reference-file-upload-massing"
            onChange={handleReferenceImageChange}
            type="file"
            style={{ display: "none" }}
          />
        </div> */}

        <div className="section-title text-center mt-5">
          <h4>Step 3: Upload References</h4>
        </div>
        <div className="mx-auto references-render">
          {referenceImages.map((image, index) => (
            <div key={index} md={4} className="reference-image-tile">
              <Image src={image.src} thumbnail />
              <div className="btn-wrap">
                <div className="d-flex">
                  <input
                    type="range"
                    id={`slider${index}`}
                    value={referenceSliders[index]}
                    className="reference-tile-slider"
                    onChange={(e) => handleSliderChange(index, e.target.value)}
                  />
                  <span className="reference-slider-value">
                    {referenceSliders[index]}
                  </span>
                </div>
                <button
                  variant="danger"
                  className="reference-tile-cancel-btn"
                  onClick={() => handleDeleteImage(index)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}

          {referenceImages?.length < 3 && (
            <div className="reference-image-upload-tile">
              <label
                htmlFor="reference-file-upload-massing"
                className="custom-reference-files-upload"
              >
                <span>+</span>
                Upload Reference Image
              </label>
              <input
                id="reference-file-upload-massing"
                onChange={handleReferenceImageChange}
                type="file"
                style={{ display: "none" }}
              />
            </div>
          )}
        </div>

        <div className="section-title text-center mt-5">
          <h4>Step 4: Upload Massing</h4>
        </div>

        <div className="mx-auto references-render">
          {massingSrc && (
            <div md={4} className="reference-image-tile">
              <Image src={massingSrc} thumbnail />
            </div>
          )}

            <div className="reference-image-upload-tile">
              <label
                htmlFor="file-upload-massing"
                className="custom-file-upload-massing"
              >
                <span>+</span>
                Upload Massing
              </label>
              <input
                id="file-upload-massing"
                onChange={handleFileChangeForMassing}
                type="file"
                style={{ display: "none" }}
              />
            </div>
        </div>

        {/* <div className="upload-references">
          <div className="upload-reference-hero">Massing</div>
          <label
            htmlFor="file-upload-massing"
            className="custom-file-upload-massing"
          >
            Upload Massing
          </label>
          <input
            id="file-upload-massing"
            onChange={handleFileChangeForMassing}
            type="file"
            style={{ display: "none" }}
          />
        </div> */}

        {/* <Row className="mx-auto references-render">
          <Col md={4} className="mb-3 mx-auto reference-image-tile">
            <Image src={massingSrc} thumbnail />
          </Col>
        </Row> */}

        <Row className="image-massing-row mt-5">
          <Col className="image-massing-col">
            <button
              className="massing-btn"
              onClick={handleFileUploadForGeneration}
            >
              Generate
            </button>
          </Col>
        </Row>
      </div>

      {isLoading && (
        <div className="loading-section">
          <Spinner
            style={{ width: "4vmax", height: "4vmax" }}
            animation="border"
          />
        </div>
      )}

      {error && (
        <div className="error-detected">
          <h2>{error}</h2>
        </div>
      )}

      {imageSrc && (
        <div className="images-generated-wrapper">
          {/* {!referenceSelect && (
                        <button className="custom-file-upload-massing show-reference-btn" onClick={() => {setReferenceSelect(true)}}>
                                View Reference Image
                        </button>
                    )}

                    {referenceSelect && (
                        <button className="style-select-nugget-clicked show-reference-btn" onClick={() => {setReferenceSelect(false)}}>
                                View Generation Image
                        </button>
                    )} */}

          {upscaleFlag && (
            <button
              className="custom-file-upload-massing show-reference-btn"
              onClick={(e) => {
                handleUpscaling(e);
              }}
              disabled={upscaleLoading}
            >
              {upscaleLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "➕ Upscale"
              )}
            </button>
          )}

          {/* {enhanceFlag && (
                        <button className="custom-file-upload-massing show-reference-btn" onClick={(e) => {handleEnhancing(e)}} disabled={enhanceLoading}>
                            {enhanceLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : '✨ Enhance'}
                        </button>
                    )} */}

          {!referenceSelect && (
            <>
              {enhancedImage && (
                <Row className="image-row">
                  <Col lg={6} md={6} className="image-col mx-auto">
                    <img
                      className="generated-massing-image"
                      style={{ pointerEvents: "none" }}
                      src={enhancedImage}
                      alt="Generated Image"
                    />
                  </Col>
                </Row>
              )}
            </>
          )}

          <button className="download-btn" onClick={handleDownloadTrigger}>
            Download
          </button>

          {!feedbackHide && (
            <div className="feedback-wrapper">
              {!feedbackSelect[1] && (
                <div
                  className="feedback-btn like-btn"
                  onClick={(e) => handleFeedbackSelect(e, 1)}
                >
                  👍
                </div>
              )}

              {feedbackSelect[1] && (
                <div className="feedback-btn-clicked like-btn">👍</div>
              )}

              {!feedbackSelect[0] && (
                <div
                  className="feedback-btn dislike-btn"
                  onClick={(e) => handleFeedbackSelect(e, 0)}
                >
                  👎
                </div>
              )}

              {feedbackSelect[0] && (
                <div className="feedback-btn-clicked dislike-btn">👎</div>
              )}

              {feedbackFlag && (
                <div className="submit-feedback">
                  <button
                    className="massing-btn feedback-submit"
                    onClick={handleFeedbackSubmit}
                  >
                    Submit Feedback
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MultiImageMassing;
