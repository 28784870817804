import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, getCurrentUser } from './Auth';
import './LoginStyles.css';
import { Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import GoogleLoginButton from './GoogleLoginButton';
import MicrosoftLoginButton from './MicrosoftLoginButton';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ username: '', password: '' });
  const [generalError, setGeneralError] = useState('');
  const navigate = useNavigate();

  useEffect(()=>{
    const isAuthenticated = getCurrentUser() !== null;
    if(isAuthenticated){
      navigate('/');
    } else {
      navigate('/login');
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const validateForm = () => {
    let valid = true;
    let errors = { username: '', password: '' };

    if (!username) {
      errors.username = 'Username is required';
      valid = false;
    }

    if (!password) {
      errors.password = 'Password is required';
      valid = false;
    }

    setError(errors);
    return valid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setLoading(true);

    try {
        await login(username, password);
        setLoading(false);
        navigate('/');
    } catch (err) {
        setLoading(false);
        setGeneralError('Invalid username or password');
    }
  };

  return (
    <div className="login-wrapper">
        <Container fluid className="vh-100 d-flex align-items-center justify-content-center">
            <Row className="w-100">
                <Col md={6} className="text-center text-md-start d-flex flex-column justify-content-center">
                <h1 className="my-5 display-3 fw-bold ls-tight px-3">
                    Welcome to <br />
                    <span style={{color: "#655BD3"}}>descon.ai Design Tools</span>
                </h1>
                </Col>

                <Col md={6}>
                <Card className="my-5">
                    <Card.Body className="p-5">
                    <Form>
                        {/* <Form.Group className="mb-4" controlId="formUsername">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Username"
                            name="username"
                            value={username}
                            onChange={handleInputChange}
                            isInvalid={!!error.username}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error.username}
                        </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            isInvalid={!!error.password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error.password}
                        </Form.Control.Feedback>
                        </Form.Group>

                        <Button className="w-100 mb-4" style={{backgroundColor: "#655BD3"}} size="md" onClick={handleSubmit} disabled={loading}>
                            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Login'}
                        </Button>

                        {generalError && (
                        <div className="text-danger text-center">
                            {generalError}
                        </div>
                        )} */}

                        <div className="text-center"> 
                          <GoogleLoginButton />
                          <MicrosoftLoginButton />
                        </div>
                    </Form>
                    </Card.Body>
                </Card>
                </Col>
            </Row>
        </Container>
    </div>
  );
};

export default Login;
