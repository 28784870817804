import "./massingStyles.css";
import { Row, Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useState } from "react";
import TextMassing from "./TextMassing";
import ImageMassing from "./ImageMassing";
import MultiImageMassing from "./MultiImageMassing";

const MassingScreen = () => {
  const [modelSelect, setModelSelect] = useState([true, false]);

  const handleModelSelect = (e, model_idx) => {
    setModelSelect((prevState) => {
      const ref = [false, false];
      ref[model_idx] = true;
      return ref;
    });
  };

  return (
    <div className="home-screen-wrapper">
      {/* <div className="hero-title-massing">
                Visualize your Massing
            </div> */}
      <div className="container-fluid no-padding">
        <Row className="massing-screen-wrapper">
          <div className="container">
            <h1 className="title-main">Visualize your Massing</h1>
            <div className="model-select-wrapper">
              {!modelSelect[0] && (
                <div
                  className="model-select-nugget"
                  onClick={(e) => {
                    handleModelSelect(e, 0);
                  }}
                >
                  Guide with Text
                </div>
              )}
              {modelSelect[0] && (
                <div className="model-select-nugget-clicked">
                  Guide with Text
                </div>
              )}
              {!modelSelect[1] && (
                <div
                  className="model-select-nugget-dy"
                  onClick={(e) => {
                    handleModelSelect(e, 1);
                  }}
                >
                  Guide with Reference ✨
                </div>
              )}
              {modelSelect[1] && (
                <div className="model-select-nugget-dy-clicked">
                  Guide with Reference ✨
                </div>
              )}
            </div>
            {modelSelect[0] && <TextMassing />}
            {/* {modelSelect[1] && <ImageMassing />} */}
            {modelSelect[1] && <MultiImageMassing />}
          </div>
        </Row>
      </div>
    </div>
  );
};

export default MassingScreen;
