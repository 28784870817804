import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './HomeScreen';
import InitialPromptSearch from './InitialPromptSearch';
import ProtectedRoute from './ProtectedRoute';
import './App.css';
import MassingScreen from './MassingScreen';
import Login from './Login';
import Layout from './Layout';
import Unknown from './Unknown';

function App() {
  return (
      <Router>
        <Layout>
          <Routes>
            <Route exact path = "/login" element={<Login />} />
            <Route exact path="/" element={<ProtectedRoute element={<HomeScreen />} />} />
            <Route exact path="/search" element={<ProtectedRoute element={<InitialPromptSearch />} />} />
            <Route exact path="/massing" element={<ProtectedRoute element={<MassingScreen />} />} />
            <Route path="*" element={<Unknown />} />
          </Routes>
        </Layout>
      </Router>
  );
}

export default App;
