import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from './Auth';

const Unknown = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = getCurrentUser() !== null;
    if (isAuthenticated) {
      navigate('/');
    } else {
      navigate('/login');
    }
  }, [navigate]);

  return null;
};

export default Unknown;