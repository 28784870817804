import { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

const GoogleLoginButton = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLoginSuccess = (tokenResponse) => {
    console.log('Google Login Success:', tokenResponse);
    const accessToken = tokenResponse.access_token;
    console.log('Access Token:', accessToken);

    fetch('https://merry-tortoise-moved.ngrok-free.app/verify-google-token', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: accessToken }), 
        })
        .then(response => response.json())
        .then(data => {
            if(data.success){
                localStorage.setItem('user', JSON.stringify(data.user));
                navigate('/');
            }   
            else {
                setError('Login failed: ' + data.message);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            setError('An error occurred during login.');
        });
    }

  const login = useGoogleLogin({
    onSuccess: handleLoginSuccess,
    onError: (errorResponse) => {
      console.error('Google Login Failed:', errorResponse);
      setError('Google Login Failed. Please try again.');
    },
    scope: 'openid profile email' 
  });

  return (
    <div>
      <button className="oauth-login-btn" onClick={(e) => {
        e.preventDefault();
        console.log("Login button clicked");
        login();
      }}>
        Login with Google
      </button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

export default GoogleLoginButton;
