import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: '3beb3971-c7c9-40c0-8514-bf31cda9fe95',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'https://ai.buildnext.tech'  
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  }
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read']
};

export const msalInstance = new PublicClientApplication(msalConfig);
